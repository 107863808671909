.signIn {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signIn-note {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

.signIn-form {
  padding: 20px;
  border: solid 1px #e8e8e8;
  border-radius: 15px;
  box-shadow: 0 0 30px #f3f1f1;
  max-width: 300px;
}

.signIn-form-button {
  width: 100%;
}
