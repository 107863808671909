.cardsContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.cardsContainer .gameCard {
  margin-top: 15px;
  margin-left: 15px;
}

.editorCards {
  display: flex;
  justify-content: space-around;
}

.editorCards img {
  width: 30%;
}
