.cardWrapper {
  padding-left: 80px;
}

.cardWrapper .cardTemplate {
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 550px;

  background: #ffe6cc;
  border: 1px solid #d79b00;
  box-sizing: border-box;
  border-radius: 20px;
}

.cardWrapper .cardTemplate .cssClass {
  margin: 10px 10px;

  background: #ffffff;
  border-radius: 20px;
}

.cardWrapper .cardTemplate .captionTemplate {
  margin: 10px 10px;
  height: 246px;
  padding-left: 10px;
  padding-right: 10px;

  background: #d5e8d4;
  border: 1px solid #82b366;
  box-sizing: border-box;
  border-radius: 20px;

  overflow: scroll;
}

.captionTemplate .externalLinks {
  margin-top: 5px;
}

.cardWrapper .cardTemplate .captionTemplate img {
  display: block;
  margin: 0 auto;
}

.cardWrapper .cardTemplate .actionInfo {
  color: rgba(0, 0, 0, 0.85);
  margin: 5px 10px;
  min-height: 15px;
  text-align: center;
}

.cardWrapper .cardTemplate .actionNotes {
  margin: 0 10px;
  min-height: 30px;
  text-align: center;
}

.cardWrapper .cardTemplate .actionNotes p {
  margin: 0 3px;
}

.cardWrapper .cardTemplate .optionsTemplate {
  margin: 10px 10px;
  height: 207px;
  padding-left: 10px;
  padding-right: 10px;

  background: #dae8fc;
  border: 1px solid #6c8ebf;
  box-sizing: border-box;
  border-radius: 20px;
}

.cardWrapper .cardTemplate .optionsTemplate ul {
  list-style: none;
  padding-left: 10px;
}

.cardWrapper .cardTemplate .optionsTemplate ul li {
  cursor: pointer;
  margin-top: 10px;
}
